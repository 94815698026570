import { Swiper, Navigation, Autoplay, EffectFade, Keyboard, A11y } from '../../node_modules/swiper/js/swiper.esm.js' // Swiper Modules: https://swiperjs.com/api/#custom-build

Swiper.use([Navigation, Autoplay, EffectFade, Keyboard, A11y])

let headerSwiper = new Swiper('#header .swiper-container', {
  keyboard: true,
  effect: 'fade',
  autoplay: {
    delay: 5000
  },
  loop: 'true',
  navigation: {
    nextEl: '.swiper-arrow-next',
    prevEl: '.swiper-arrow-prev'
  },
  on: {
    init: function () {
      $('.swiper-slide.swiper-slide-active').addClass('transitionEnd')
      console.log('init')
    },
    slideChangeTransitionEnd: function () {
      $('.swiper-slide').removeClass('transitionEnd')
      $('.swiper-slide.swiper-slide-active').addClass('transitionEnd')
    },
    slideChangeTransitionStart: function () {
      $('.swiper-slide').removeClass('transitionEnd')
    }
  }
})

let infoSwiper = new Swiper('#info .swiper-container', {
  keyboard: true,
  effect: 'fade',
  autoplay: {
    delay: 5000
  },
  navigation: {
    nextEl: '.swiper-arrow-next',
    prevEl: '.swiper-arrow-prev'
  },
  on: {
    init: function () {
      $('.swiper-slide.swiper-slide-active').addClass('transitionEnd')
      console.log('init')
    },
    slideChangeTransitionEnd: function () {
      $('.swiper-slide').removeClass('transitionEnd')
      $('.swiper-slide.swiper-slide-active').addClass('transitionEnd')
    },
    slideChangeTransitionStart: function () {
      $('.swiper-slide').removeClass('transitionEnd')
    }
  }
})
