// TODO: locations aktualisieren
var locations = [{
  lat: 51.765123,
  lng: 9.377165,
  title: 'Weser-Diemel-Beton Höxter GmbH & Co. KG',
  subtitle: 'Verwaltung & Werk',
  zip: 37671,
  city: 'Höxter',
  street: 'Godelheimer Str. 56',
  phone: '05271 6909-20',
  type: '',
  vertrieb: 'Peter Altmiks'
}, {
  lat: 51.728585,
  lng: 9.058298,
  title: 'Bad Driburg',
  subtitle: 'OT Herste',
  zip: 33014,
  city: 'Bad Driburg',
  street: 'Ostenfeldmark 9',
  phone: '05253 3074',
  type: ' - Werk',
  vertrieb: 'Peter Altmiks'
}, {
  lat: 51.983823,
  lng: 9.490614,
  title: 'Bodenwerder',
  subtitle: 'OT Kemnade',
  zip: 37619,
  city: 'Bodenwerder',
  street: 'An der B83',
  phone: '05533 3054',
  type: ' - Werk',
  vertrieb: 'Maximilian Pöllmann'
}, {
  lat: 51.647340,
  lng: 9.661661,
  title: 'Uslar',
  subtitle: '',
  zip: 37170,
  city: 'Uslar',
  street: 'Am Kalksandsteinwerk',
  phone: '05571 71 12',
  type: ' - Werk',
  vertrieb: 'Maximilian Pöllmann'
}, {
  lat: 51.513407,
  lng: 9.078082,
  title: 'Weser-Diemel-Beton Warburg GmbH & Co. KG',
  subtitle: 'Verwaltung & Werk',
  zip: 34414,
  city: 'Warburg-Ossendorf',
  street: 'Zur Mühle 12-13',
  phone: '05642 9808-0',
  type: '',
  vertrieb: 'Hubert Hoppe'
}, {
  lat: 51.380577,
  lng: 9.040306,
  title: 'Bad Arolsen',
  subtitle: '',
  zip: 34454,
  city: 'Bad Arolsen',
  street: 'Wetterburger Straße',
  phone: '05691 / 2450',
  type: ' - Werk',
  vertrieb: 'Hubert Hoppe'
}, {
  lat: 51.312629,
  lng: 9.260700,
  title: 'Oelshausen',
  subtitle: '',
  zip: 34289,
  city: 'Zierenberg-Oelshausen',
  street: 'Wattenbergstraße 10',
  phone: '05606 / 9063',
  type: ' - Werk',
  vertrieb: 'Hubert Hoppe'
}, {
  lat: 51.403998,
  lng: 9.181396,
  title: 'Breuna',
  subtitle: '',
  zip: 34479,
  city: 'Breuna',
  street: 'Im Strang 7',
  phone: '05693 / 98710',
  type: ' - Werk',
  vertrieb: 'Hubert Hoppe'
}
]

const deliveryAreaCoords3 = [

  { lng: 8.7044145, lat: 51.7032134 },
  { lng: 8.7140298, lat: 51.6795899 },
  { lng: 8.7057881, lat: 51.6578709 },
  { lng: 8.7279041, lat: 51.6209001 },
  { lng: 8.7305239, lat: 51.5939786 },
  { lng: 8.7205652, lat: 51.5805399 },
  { lng: 8.7141793, lat: 51.5596972 },
  { lng: 8.7148661, lat: 51.5492373 },
  { lng: 8.7036210, lat: 51.5319486 },
  { lng: 8.7002164, lat: 51.5255093 },
  { lng: 8.6964390, lat: 51.5208097 },
  { lng: 8.6924268, lat: 51.5125560 },
  { lng: 8.6705374, lat: 51.4922375 },
  { lng: 8.6589784, lat: 51.4876548 },
  { lng: 8.6581534, lat: 51.4824695 },
  { lng: 8.6481947, lat: 51.4749857 },
  { lng: 8.6375491, lat: 51.4670729 },
  { lng: 8.6358321, lat: 51.4610839 },
  { lng: 8.6265602, lat: 51.4568056 },
  { lng: 8.6314765, lat: 51.4456492 },
  { lng: 8.6402977, lat: 51.4392059 },
  { lng: 8.6358335, lat: 51.4332133 },
  { lng: 8.5988949, lat: 51.4092717 },
  { lng: 8.5799392, lat: 51.3969047 },
  { lng: 8.5586589, lat: 51.3931375 },
  { lng: 8.5852190, lat: 51.3848707 },
  { lng: 8.5878281, lat: 51.3751023 },
  { lng: 8.5923407, lat: 51.3701419 },
  { lng: 8.5802436, lat: 51.3575055 },
  { lng: 8.5737190, lat: 51.3463558 },
  { lng: 8.5777016, lat: 51.3339114 },
  { lng: 8.5869333, lat: 51.3233150 },
  { lng: 8.5877570, lat: 51.3139618 },
  { lng: 8.5915143, lat: 51.3003162 },
  { lng: 8.6048293, lat: 51.2889178 },
  { lng: 8.6295544, lat: 51.2773219 },
  { lng: 8.6607063, lat: 51.2766851 },
  { lng: 8.6656709, lat: 51.2815574 },
  { lng: 8.6870693, lat: 51.2759066 },
  { lng: 8.6998745, lat: 51.2694747 },
  { lng: 8.7027787, lat: 51.2633776 },
  { lng: 8.6881893, lat: 51.2595793 },
  { lng: 8.6847553, lat: 51.2488356 },
  { lng: 8.6678789, lat: 51.2437927 },
  { lng: 8.6424669, lat: 51.2289606 },
  { lng: 8.6225495, lat: 51.2244456 },
  { lng: 8.6100984, lat: 51.2225734 },
  { lng: 8.5794764, lat: 51.2099257 },
  { lng: 8.5674572, lat: 51.1983099 },
  { lng: 8.5688308, lat: 51.1920705 },
  { lng: 8.5805066, lat: 51.1858303 },
  { lng: 8.6033081, lat: 51.1853428 },
  { lng: 8.6308395, lat: 51.1814178 },
  { lng: 8.6369818, lat: 51.1731540 },
  { lng: 8.6500312, lat: 51.1654043 },
  { lng: 8.6699705, lat: 51.1687958 },
  { lng: 8.6695091, lat: 51.1604154 },
  { lng: 8.6804296, lat: 51.1502694 },
  { lng: 8.6878960, lat: 51.1483740 },
  { lng: 8.7011707, lat: 51.1474183 },
  { lng: 8.7131898, lat: 51.1400950 },
  { lng: 8.7262392, lat: 51.1345101 },
  { lng: 8.7387493, lat: 51.1327016 },
  { lng: 8.7607473, lat: 51.1309418 },
  { lng: 8.7841471, lat: 51.1282680 },
  { lng: 8.7896228, lat: 51.1259812 },
  { lng: 8.8016715, lat: 51.1245941 },
  { lng: 8.8175378, lat: 51.1283235 },
  { lng: 8.8275650, lat: 51.1373081 },
  { lng: 8.8505708, lat: 51.1420942 },
  { lng: 8.8629336, lat: 51.1474789 },
  { lng: 8.8696058, lat: 51.1577912 },
  { lng: 8.8799080, lat: 51.1618822 },
  { lng: 8.8771607, lat: 51.1676648 },
  { lng: 8.8852347, lat: 51.1785392 },
  { lng: 8.8907496, lat: 51.1739621 },
  { lng: 8.8993736, lat: 51.1600260 },
  { lng: 8.9022483, lat: 51.1490739 },
  { lng: 8.9090573, lat: 51.1362554 },
  { lng: 8.9226178, lat: 51.1256386 },
  { lng: 8.9203025, lat: 51.1181334 },
  { lng: 8.9317730, lat: 51.1114042 },
  { lng: 8.9376015, lat: 51.1089490 },
  { lng: 8.9338240, lat: 51.1016196 },
  { lng: 8.9362279, lat: 51.0927797 },
  { lng: 8.9425460, lat: 51.0915070 },
  { lng: 8.9528977, lat: 51.0883937 },
  { lng: 8.9639162, lat: 51.0922390 },
  { lng: 8.9656332, lat: 51.0967670 },
  { lng: 8.9727656, lat: 51.0999606 },
  { lng: 8.9744826, lat: 51.1053501 },
  { lng: 8.9830395, lat: 51.1067765 },
  { lng: 8.9840697, lat: 51.1130274 },
  { lng: 8.9820093, lat: 51.1220789 },
  { lng: 8.9997389, lat: 51.1216749 },
  { lng: 9.0188195, lat: 51.1291686 },
  { lng: 9.0240095, lat: 51.1344057 },
  { lng: 9.0380038, lat: 51.1373552 },
  { lng: 9.0665077, lat: 51.1423096 },
  { lng: 9.0926067, lat: 51.1479096 },
  { lng: 9.1084033, lat: 51.1496325 },
  { lng: 9.1231707, lat: 51.1569543 },
  { lng: 9.1197366, lat: 51.1500632 },
  { lng: 9.1184609, lat: 51.1425831 },
  { lng: 9.1200803, lat: 51.1354164 },
  { lng: 9.1304307, lat: 51.1315268 },
  { lng: 9.1430870, lat: 51.1323145 },
  { lng: 9.1565777, lat: 51.1273338 },
  { lng: 9.1592767, lat: 51.1239653 },
  { lng: 9.1754167, lat: 51.1289211 },
  { lng: 9.2062453, lat: 51.1249333 },
  { lng: 9.2347586, lat: 51.1241774 },
  { lng: 9.2484948, lat: 51.1241740 },
  { lng: 9.2577667, lat: 51.1136143 },
  { lng: 9.2669501, lat: 51.1074594 },
  { lng: 9.2931762, lat: 51.1017813 },
  { lng: 9.3062256, lat: 51.0953133 },
  { lng: 9.3251129, lat: 51.0950976 },
  { lng: 9.3452946, lat: 51.0910024 },
  { lng: 9.3664971, lat: 51.0926011 },
  { lng: 9.3730218, lat: 51.0917386 },
  { lng: 9.3771427, lat: 51.0984227 },
  { lng: 9.3980809, lat: 51.1088589 },
  { lng: 9.4125039, lat: 51.1127386 },
  { lng: 9.4269269, lat: 51.1211437 },
  { lng: 9.4349173, lat: 51.1298149 },
  { lng: 9.4620151, lat: 51.1211959 },
  { lng: 9.4743777, lat: 51.1209804 },
  { lng: 9.4888007, lat: 51.1237818 },
  { lng: 9.5076879, lat: 51.1283067 },
  { lng: 9.5272620, lat: 51.1311076 },
  { lng: 9.5471794, lat: 51.1298149 },
  { lng: 9.5595426, lat: 51.1295995 },
  { lng: 9.5765357, lat: 51.1264946 },
  { lng: 9.5873589, lat: 51.1328312 },
  { lng: 9.5935409, lat: 51.1386477 },
  { lng: 9.6087084, lat: 51.1457550 },
  { lng: 9.6233831, lat: 51.1466174 },
  { lng: 9.6395231, lat: 51.1539396 },
  { lng: 9.6539461, lat: 51.1599688 },
  { lng: 9.6676823, lat: 51.1720248 },
  { lng: 9.6851959, lat: 51.1838625 },
  { lng: 9.6944678, lat: 51.1825712 },
  { lng: 9.7003061, lat: 51.1916091 },
  { lng: 9.7126593, lat: 51.1946810 },
  { lng: 9.7267389, lat: 51.1959719 },
  { lng: 9.7239916, lat: 51.1981233 },
  { lng: 9.7209010, lat: 51.2026410 },
  { lng: 9.7325650, lat: 51.2096795 },
  { lng: 9.7397766, lat: 51.2137659 },
  { lng: 9.7428673, lat: 51.2167767 },
  { lng: 9.7504225, lat: 51.2277429 },
  { lng: 9.7651594, lat: 51.2315766 },
  { lng: 9.7713407, lat: 51.2289968 },
  { lng: 9.7785521, lat: 51.2412497 },
  { lng: 9.7795824, lat: 51.2552183 },
  { lng: 9.7963998, lat: 51.2591806 },
  { lng: 9.8146405, lat: 51.2578076 },
  { lng: 9.8314673, lat: 51.2625342 },
  { lng: 9.8345579, lat: 51.2696232 },
  { lng: 9.8458903, lat: 51.2752077 },
  { lng: 9.8427996, lat: 51.2840127 },
  { lng: 9.8493243, lat: 51.2956069 },
  { lng: 9.8503545, lat: 51.3082713 },
  { lng: 9.8452035, lat: 51.3174991 },
  { lng: 9.8548188, lat: 51.3323026 },
  { lng: 9.8616869, lat: 51.3353056 },
  { lng: 9.8570563, lat: 51.3437020 },
  { lng: 9.8498448, lat: 51.3469187 },
  { lng: 9.8453805, lat: 51.3546379 },
  { lng: 9.8340482, lat: 51.3582827 },
  { lng: 9.8175648, lat: 51.3653569 },
  { lng: 9.8017682, lat: 51.3745732 },
  { lng: 9.7990209, lat: 51.3852875 },
  { lng: 9.7894056, lat: 51.3893583 },
  { lng: 9.7804771, lat: 51.3910722 },
  { lng: 9.7773864, lat: 51.3951424 },
  { lng: 9.8093231, lat: 51.4039244 },
  { lng: 9.8302707, lat: 51.4122764 },
  { lng: 9.8453805, lat: 51.4227678 },
  { lng: 9.8474410, lat: 51.4321866 },
  { lng: 9.8566431, lat: 51.4442240 },
  { lng: 9.8686623, lat: 51.4606979 },
  { lng: 9.8765606, lat: 51.4927727 },
  { lng: 9.8789644, lat: 51.5374261 },
  { lng: 9.8791645, lat: 51.5553014 },
  { lng: 9.8825986, lat: 51.5649063 },
  { lng: 9.8956479, lat: 51.5708816 },
  { lng: 9.9059501, lat: 51.5738690 },
  { lng: 9.9128182, lat: 51.5824033 },
  { lng: 9.9045765, lat: 51.5939220 },
  { lng: 9.9083539, lat: 51.6094890 },
  { lng: 9.9183126, lat: 51.6280344 },
  { lng: 9.9217467, lat: 51.6425244 },
  { lng: 9.9162817, lat: 51.6576538 },
  { lng: 9.9241800, lat: 51.6736247 },
  { lng: 9.9331085, lat: 51.6910798 },
  { lng: 9.9382596, lat: 51.7038476 },
  { lng: 9.9430673, lat: 51.7166117 },
  { lng: 9.9479465, lat: 51.7216474 },
  { lng: 9.9640865, lat: 51.7314298 },
  { lng: 9.9726716, lat: 51.7327056 },
  { lng: 9.9664903, lat: 51.7516257 },
  { lng: 9.9589354, lat: 51.7648014 },
  { lng: 9.9366141, lat: 51.7813717 },
  { lng: 9.9321499, lat: 51.7909288 },
  { lng: 9.9177269, lat: 51.8019699 },
  { lng: 9.9197873, lat: 51.8077018 },
  { lng: 9.9373009, lat: 51.8142819 },
  { lng: 9.9565316, lat: 51.8240442 },
  { lng: 9.9527541, lat: 51.8321071 },
  { lng: 9.9657257, lat: 51.8370048 },
  { lng: 9.9770580, lat: 51.8414595 },
  { lng: 9.9763712, lat: 51.8463380 },
  { lng: 9.9670993, lat: 51.8533366 },
  { lng: 9.9633219, lat: 51.8582137 },
  { lng: 9.9509593, lat: 51.8582137 },
  { lng: 9.9406572, lat: 51.8571535 },
  { lng: 9.9317287, lat: 51.8575776 },
  { lng: 9.9197095, lat: 51.8609702 },
  { lng: 9.9039129, lat: 51.8654225 },
  { lng: 9.8898333, lat: 51.8658465 },
  { lng: 9.8754103, lat: 51.8660585 },
  { lng: 9.8809048, lat: 51.8813201 },
  { lng: 9.8785010, lat: 51.8917035 },
  { lng: 9.8661384, lat: 51.9008135 },
  { lng: 9.8372428, lat: 51.9179005 },
  { lng: 9.8241934, lat: 51.9301808 },
  { lng: 9.8211028, lat: 51.9386480 },
  { lng: 9.8248802, lat: 51.9477484 },
  { lng: 9.8159517, lat: 51.9615014 },
  { lng: 9.8073666, lat: 51.9680591 },
  { lng: 9.8128611, lat: 51.9777880 },
  { lng: 9.8001551, lat: 51.9856119 },
  { lng: 9.8011853, lat: 51.9896291 },
  { lng: 9.7946606, lat: 52.0052714 },
  { lng: 9.7812679, lat: 52.0149922 },
  { lng: 9.7631173, lat: 52.0387654 },
  { lng: 9.7442301, lat: 52.0769740 },
  { lng: 9.7435478, lat: 52.0996103 },
  { lng: 9.7308418, lat: 52.1067803 },
  { lng: 9.7188227, lat: 52.1198521 },
  { lng: 9.7284380, lat: 52.1320771 },
  { lng: 9.7342759, lat: 52.1417703 },
  { lng: 9.7397703, lat: 52.1516722 },
  { lng: 9.7243171, lat: 52.1579913 },
  { lng: 9.7143584, lat: 52.1617824 },
  { lng: 9.7098941, lat: 52.1687318 },
  { lng: 9.7026719, lat: 52.1634573 },
  { lng: 9.6861885, lat: 52.1590345 },
  { lng: 9.6632631, lat: 52.1551223 },
  { lng: 9.6570818, lat: 52.1704962 },
  { lng: 9.6457495, lat: 52.1715490 },
  { lng: 9.6230848, lat: 52.1671270 },
  { lng: 9.6117524, lat: 52.1633364 },
  { lng: 9.5990465, lat: 52.1561755 },
  { lng: 9.5990465, lat: 52.1492241 },
  { lng: 9.5942388, lat: 52.1426930 },
  { lng: 9.5715741, lat: 52.1441678 },
  { lng: 9.5616154, lat: 52.1300494 },
  { lng: 9.5523435, lat: 52.1224615 },
  { lng: 9.5451320, lat: 52.1188779 },
  { lng: 9.5320826, lat: 52.1205644 },
  { lng: 9.5176596, lat: 52.1169806 },
  { lng: 9.5015196, lat: 52.1129749 },
  { lng: 9.4860664, lat: 52.1060167 },
  { lng: 9.4709566, lat: 52.1055950 },
  { lng: 9.4486353, lat: 52.1053841 },
  { lng: 9.4259706, lat: 52.1011665 },
  { lng: 9.4104664, lat: 52.1003484 },
  { lng: 9.3919226, lat: 52.1005593 },
  { lng: 9.3812771, lat: 52.1020356 },
  { lng: 9.3675409, lat: 52.1018247 },
  { lng: 9.3606728, lat: 52.1047770 },
  { lng: 9.3517443, lat: 52.1047770 },
  { lng: 9.3448762, lat: 52.0997157 },
  { lng: 9.3376647, lat: 52.1028791 },
  { lng: 9.3187775, lat: 52.1113137 },
  { lng: 9.3060715, lat: 52.1180602 },
  { lng: 9.2954260, lat: 52.1197467 },
  { lng: 9.2906183, lat: 52.1130004 },
  { lng: 9.2816898, lat: 52.1070966 },
  { lng: 9.2751651, lat: 52.1066749 },
  { lng: 9.2614289, lat: 52.1085726 },
  { lng: 9.2377045, lat: 52.1029508 },
  { lng: 9.2307479, lat: 52.0916415 },
  { lng: 9.2513521, lat: 52.0794055 },
  { lng: 9.2678356, lat: 52.0715979 },
  { lng: 9.2678356, lat: 52.0656886 },
  { lng: 9.2513521, lat: 52.0701207 },
  { lng: 9.2300611, lat: 52.0745523 },
  { lng: 9.2080832, lat: 52.0690655 },
  { lng: 9.1933168, lat: 52.0658997 },
  { lng: 9.1679049, lat: 52.0646333 },
  { lng: 9.1545121, lat: 52.0665329 },
  { lng: 9.1424929, lat: 52.0680102 },
  { lng: 9.1576027, lat: 52.0606229 },
  { lng: 9.1727125, lat: 52.0570343 },
  { lng: 9.1668747, lat: 52.0492229 },
  { lng: 9.1641274, lat: 52.0390871 },
  { lng: 9.1479673, lat: 52.0358029 },
  { lng: 9.1342311, lat: 52.0358029 },
  { lng: 9.1163741, lat: 52.0305225 },
  { lng: 9.1074456, lat: 52.0303113 },
  { lng: 9.0933660, lat: 52.0309450 },
  { lng: 9.0810034, lat: 52.0296776 },
  { lng: 9.0734485, lat: 52.0320011 },
  { lng: 9.0669238, lat: 52.0273540 },
  { lng: 9.0590255, lat: 52.0254528 },
  { lng: 9.0595849, lat: 52.0140926 },
  { lng: 9.0403543, lat: 52.0153604 },
  { lng: 9.0194066, lat: 52.0115568 },
  { lng: 9.0001759, lat: 52.0056396 },
  { lng: 8.9926210, lat: 52.0022580 },
  { lng: 8.9953683, lat: 51.9859803 },
  { lng: 8.9809453, lat: 51.9944370 },
  { lng: 8.9654921, lat: 52.0031034 },
  { lng: 8.9490087, lat: 52.0031034 },
  { lng: 8.9493521, lat: 51.9961281 },
  { lng: 8.9531295, lat: 51.9897860 },
  { lng: 8.9411104, lat: 51.9838659 },
  { lng: 8.9277176, lat: 51.9876717 },
  { lng: 8.9318385, lat: 51.9709658 },
  { lng: 8.9215363, lat: 51.9682161 },
  { lng: 8.9095172, lat: 51.9686391 },
  { lng: 8.9065338, lat: 51.9567718 },
  { lng: 8.8982921, lat: 51.9483081 },
  { lng: 8.8876466, lat: 51.9389960 },
  { lng: 8.8800917, lat: 51.9349744 },
  { lng: 8.8776879, lat: 51.9311640 },
  { lng: 8.8687594, lat: 51.9345510 },
  { lng: 8.8598308, lat: 51.9309523 },
  { lng: 8.8515891, lat: 51.9290470 },
  { lng: 8.8450644, lat: 51.9243892 },
  { lng: 8.8352922, lat: 51.9243925 },
  { lng: 8.8109105, lat: 51.9222752 },
  { lng: 8.7968309, lat: 51.9176168 },
  { lng: 8.7892760, lat: 51.9078748 },
  { lng: 8.7717624, lat: 51.9201578 },
  { lng: 8.7631772, lat: 51.9248160 },
  { lng: 8.7442900, lat: 51.9133814 },
  { lng: 8.7260896, lat: 51.9089338 },
  { lng: 8.7357049, lat: 51.9051212 },
  { lng: 8.7322708, lat: 51.8943172 },
  { lng: 8.7360483, lat: 51.8862654 },
  { lng: 8.7060447, lat: 51.8747225 },
  { lng: 8.6925245, lat: 51.8650366 },
  { lng: 8.6987057, lat: 51.8537988 },
  { lng: 8.6997360, lat: 51.8355579 },
  { lng: 8.7124419, lat: 51.8236762 },
  { lng: 8.7220572, lat: 51.8192197 },
  { lng: 8.7213704, lat: 51.7890739 },
  { lng: 8.7203402, lat: 51.7716566 },
  { lng: 8.7155527, lat: 51.7700110 },
  { lng: 8.7179565, lat: 51.7608745 },
  { lng: 8.7155527, lat: 51.7493981 },
  { lng: 8.7045637, lat: 51.7374937 },
  { lng: 8.7059374, lat: 51.7185676 },
  { lng: 8.7044145, lat: 51.7032134 }
]

const mapStyles = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
      {
        saturation: '100'
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#566c7f'
      },
      {
        saturation: '14'
      }
    ]
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 65
      },
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        saturation: '-100'
      },
      {
        lightness: '50'
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: '-100'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      },
      {
        saturation: '-100'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#566c7f'
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        lightness: '30'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        lightness: '40'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        lightness: '-25'
      },
      {
        saturation: '9'
      },
      {
        color: '#566c7f'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        lightness: -25
      },
      {
        saturation: -100
      }
    ]
  }
]
const mapCanvas = document.getElementById('map-canvas')

function initMap () {
  let activeInfoWindow
  const bounds = new google.maps.LatLngBounds()
  const markerIcon = {
    url: 'https://weser-diemel-beton.de/typo3conf/ext/hotsushi_configuration/Resources/Public/Images/pin_neongreen.png',
    scaledSize: new google.maps.Size(30, 44),
    origin: new google.maps.Point(0, 0)
  }
  const markerIconLight = {
    url: 'https://weser-diemel-beton.de/typo3conf/ext/hotsushi_configuration/Resources/Public/Images/pin_neongreen.png',
    scaledSize: markerIcon.scaledSize,
    origin: markerIcon.origin
  }

  const map = new google.maps.Map(mapCanvas, {
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    styles: mapStyles
  })

  const deliveryArea = new google.maps.Polygon({
    paths: deliveryAreaCoords3,
    strokeColor: '#bed643',
    strokeOpacity: 1,
    strokeWeight: 0,
    fillColor: '#000000',
    fillOpacity: 0.1
  })
  deliveryArea.setMap(map)

  locations.forEach(location => {
    const latLng = new google.maps.LatLng(location.lat, location.lng)
    const marker = new google.maps.Marker({
      position: latLng,
      map: map,
      title: location.title,
      icon: location.type === 'Werk' ? markerIcon : markerIconLight
    })

    bounds.extend(marker.position)

    var contentString = "<div id=\"gm-info-window\">\n    <h5 id=\"firstHeading\" class=\"firstHeading\">".concat(location.title, " ").concat(location.type, "</h5>\n    <div id=\"bodyContent\">\n    <p>\n    ").concat(location.subtitle, "<br>\n    ").concat(location.street, "<br>\n    ").concat(location.zip, " ").concat(location.city, "<br>\n    Tel: <a href=\"tel:").concat(location.phone, "\">").concat(location.phone, "</a>\n    <br>\n    ").concat("Vertrieb: "+location.vertrieb, "</p>\n    </div>\n    </div>");

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 350
    })

    marker.addListener('click', () => {
      if (activeInfoWindow) {
        activeInfoWindow.close()
      }
      infowindow.open(map, marker)
      activeInfoWindow = infowindow
    })
  })

  map.fitBounds(bounds) // Center map by all markers
}

if (mapCanvas) {
  window.addEventListener('load', () => initMap())
}
